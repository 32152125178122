import _getPrototypeOf from "./getPrototypeOf.js";
import _isNativeReflectConstruct from "./isNativeReflectConstruct.js";
import _possibleConstructorReturn from "./possibleConstructorReturn.js";
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var getPrototypeOf = _getPrototypeOf;
var isNativeReflectConstruct = _isNativeReflectConstruct;
var possibleConstructorReturn = _possibleConstructorReturn;
function _createSuper(Derived) {
  var hasNativeReflectConstruct = isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = getPrototypeOf(this || _global).constructor;
      result = Reflect.construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this || _global, arguments);
    }
    return possibleConstructorReturn(this || _global, result);
  };
}
exports = _createSuper, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;